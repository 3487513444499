import { getTimes } from 'suncalc';

/** Get weather info from api.met.no (yr.no)
 * Weather api documentioation: http://api.met.no/weatherapi/locationforecastlts/1.2/documentation
 */
// const testingData = { 'weather': { 'currTemp': -14.4, 'windDir': 'ENE', 'windSpeed': 3.4, 'icoSymbol': 'PartlyCloud' }, 'geoip': { 'country_name': 'Latvia', 'city': 'Riga', 'latitude': 56.950000762939, 'longitude': 24.10000038147 } };

function render(selector, data, i18n) {
    // data = testingData;
    const
        {
            geoip,
            weather
        } = data,
        {
            currTemp = null,
            icoSymbol = null,
            windDir = null,
            windSpeed = null
        } = weather,
        hasAllValues = Object.keys(weather).every(key => weather[key] !== null),
        weatherEl = document.querySelector(selector);

    if (!weatherEl) {
        return;
    }
    // Handle case when json is received, but current weather coditions contains "null" values
    if (!hasAllValues) {
        return;
    }
    // console.log(geoip);
    const
        icoName    = icoSymbol.toLowerCase(),
        icoText    = icoSymbol.replace(/([A-Z])/g, ' $1').trim(),
        now        = new Date(),
        times      = getTimes(now, geoip.latitude, geoip.longitude),
        isDayTime  = (now < times.sunset && now > times.sunrise) ? true : false,
        weatherUrl = `https://www.yr.no/en/forecast/daily-table/${geoip.latitude},${geoip.longitude}`;
    // Calculcate date/night with SunCalc
    // var sunriseStr = times.sunrise.getHours() + ':' + times.sunrise.getMinutes();
    // var sunsetStr = times.sunset.getHours() + ':' + times.sunset.getMinutes();

    weatherEl.style.display = 'block';
    weatherEl.setAttribute('href', weatherUrl);
    weatherEl.querySelector('.weather__city').textContent = geoip.city.replace('Riga', 'Rīga');
    const weatherTempEl = weatherEl.querySelector('.weather__temp');
    weatherTempEl.textContent = `${currTemp}`;
    weatherTempEl.innerHTML = weatherTempEl.innerHTML + '&#8451;';
    weatherEl.querySelector('.weather__wind').textContent = `${i18n.directions[windDir]} ${i18n.wind} ${windSpeed} ${i18n.speed}`;
    const meteoIcoEl = weatherEl.querySelector('.ico-meteo');
    meteoIcoEl.setAttribute('title', icoText);
    // For the night add suffix "-moon".
    meteoIcoEl.classList.add(`imi-${icoName}`, `imi-${icoName}${isDayTime ? '' : '-moon'}`);
}

export default async function (selector, params, i18n) {
    if (!params) {
        return;
    }
    if (params.apiUrl === undefined) {
        return;
    }
    if (params.enabled && params.enabled === false) {
        return;
    }
    // Get weather json
    try {
        //params.apiUrl Defined in head_assets template
        const data = await fetch(params.apiUrl, {
            method: 'GET'
        });
        const jsonResponse = await data.json();
        render(selector, jsonResponse, i18n);

        const portalWeatherSelector = '.col-today .col-weather';
        const portalWeatherEl = document.querySelector(portalWeatherSelector);
        if (portalWeatherEl) {
            render(portalWeatherSelector, jsonResponse, i18n);
        }
    } catch (err) {
        console.log(err);
    }
}
