import { trimSlashes } from '../utils';

// Construct mapi request with or without messages
const getMapiUrl = (params) => {
    const
        {
            fetchMailBodies = false,
            mapiHost,
            userMail
        } = params,
        fetchMessages = fetchMailBodies ? '/messages?peek=true&limit=13' : '';

    return `//${trimSlashes(mapiHost)}/mailbox/${userMail}/folders/INBOX${fetchMessages}`;
};

export const getMailUrls = (params) => {
    const mailHost = trimSlashes(params.mailHost);

    return {
        mapiURL: getMapiUrl(params),
        recentURL: `//${mailHost}/mailbox?mailbox=INBOX`,
        unreadURL: `//${mailHost}/mailbox?inFolders=INBOX&type=unSeen&mailbox=INBOX`
    };
};
