import { getMailUrls } from '../config/';

// For testing only!
/* function testData() {
    const respJson = { 'name': 'INBOX', 'id': 'INBOX', 'fullname': 'INBOX', 'href': 'http://mapi.indev.lv/mailbox/testdev1@inbox.lv/folders/INBOX/messages?access_token=', 'unread': 27, 'recent': 5, 'state': { 'highestmodseq': 41967, 'uidvalidity': 1341234912 } };
    return new Promise((resolve) => {
        INBOX.observer.notify('mailDataFetched', respJson);
        resolve(respJson);
    });
} */

const dataFetcher = (INBOX, mapiURL) => () => {
    const { observer } = INBOX;

    INBOX.namespace('store.mailFetch', async () => {
        try {
            const data = await fetch(mapiURL, {
                method: 'GET',
                credentials: 'include'
            });
            const jsonResponse = await data.json();
            // console.log('Toolbar: mail data fetched!', jsonResponse);
            observer.notify('mailDataFetched', jsonResponse);
            return jsonResponse;
        } catch (err) {
            // console.error('Unable to fetch the mail data!');
            observer.notify('mailDataFetchFailed', err);
        }
    });
    INBOX.store.mailFetch();
};

export default (INBOX, params) => {
    const
        { mapiFetchInterval } = params,
        { mapiURL } = getMailUrls(params),
        fetchData = dataFetcher(INBOX, mapiURL);

    if (mapiFetchInterval > 0) {
        setInterval(() => {
            // testData(mapiURL);
            fetchData();
        }, mapiFetchInterval * 1000);
        // }, 5 * 1000);
    }
    fetchData();
};
