// const positionLangDropDown = ({ target }) => {
//     const dropDown = target.parentNode.querySelector('.dropdown-menu');
//     const icon = target.querySelector('.ico-lang');
// };

const goToLanguage = (lang) => {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    params.set('language', lang);
    params.set('persist_language', 1);
    url.search = params.toString();
    location.href = url.toString();
};

// Provides visual feedback while loading new language
const changeLanguage = (e, switcher) => {
    const liTag = e.target.closest('li');

    if (!liTag) return;

    const icon = switcher.querySelector('.ico-lang');
    const newLang = liTag.dataset.lang;
    const oldLang = icon.className.match(/\bflag-\w{2}/)[0];

    icon.classList.remove(oldLang);
    icon.classList.add(`flag-${newLang}`);

    goToLanguage(newLang);
};

// PORTAL-1203 - resolve language icon size problem on Mac, 4k monitor, not on safari browsers
const applyHighDPIStyles = () => {
    const isMacNonSafariBrowser = !navigator.userAgent.includes('Safari') && navigator.platform.includes('Mac');
    const is4k = window.screen.width >= 3840 && window.screen.height >= 2160;
    const isHighDPI = window.devicePixelRatio >= 2;

    if (isMacNonSafariBrowser && is4k && isHighDPI) {
        document.getElementById('inx-lang-switch-button')?.classList.add('language-switch--4k');
    }
};

export default (selector) => {
    const container = document.getElementById(selector);
    if (!container) return;
    
    const elm = {
        switcher: container.querySelector('.language-switch'),
        dropdown: container.querySelector('.dropdown-menu')
    };

    applyHighDPIStyles();

    // container.addEventListener('shown.bs.dropdown', positionLangDropDown);
    elm.dropdown.addEventListener('click', (e) => changeLanguage(e, elm.switcher));
};
